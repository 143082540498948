import React, { Component } from "react";
import {
  goBack,
  executePost,
  executeGet,
  formatTanggal,
} from "../functions/Kubuku";
import { TITIK_BACA, TITIK_BACA_BERITA } from "../api";
import Glider from "react-glider";
import "glider-js/glider.min.css";
import parse from "html-react-parser";

import ModalLoading from "../components/ModalLoading";

import { useParams, useNavigate } from "react-router-dom";

function WithNavigate(props) {
  let navigate = useNavigate();
  return <GamesPage {...props} navigate={navigate} params={useParams()} />;
}

class GamesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      showMain: false,
      params: this.props.params,
      id: this.props.params.id,
      data: "",
      alias: this.props.params.alias,
      lokasi: "",
      flagData: false,
      flagBerita: false,
      urlGames: "",
      color: window.localStorage.getItem("color"),
    };
  }

  componentDidMount = () => {
    //this.getRSS();
    if (this.state.id == "1") {
      this.setState({
        urlGames:
          "https://kubuku.id/api/wl/transitUrl/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MTcwOTA2NjgsImV4cCI6MTcxNzE3NzA2OCwiaWRfdXNlciI6IjIxMjM2IiwiaWRfbWl0cmEiOiI1MzciLCJkZXN0X3VybCI6Imh0dHBzOlwvXC9rdWJ1a3UuaWRcL2dhbWVzXC9tYXRoZ2VuaXVzXC8ifQ.qqmqN8qQtH5-phE78YBwMZcdVkJ66eu4v9VOVf695Ag",
      });
    } else if (this.state.id == "2") {
      this.setState({
        urlGames:
          "https://kubuku.id/api/wl/transitUrl/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MTcwOTA2NjgsImV4cCI6MTcxNzE3NzA2OCwiaWRfdXNlciI6IjIxMjM2IiwiaWRfbWl0cmEiOiI1MzciLCJkZXN0X3VybCI6Imh0dHBzOlwvXC9rdWJ1a3UuaWRcL2dhbWVzXC9nZXRfdGhlX3dvcmQifQ.m0vpmrfoyXOUsGqvpVHv66o9F334x2fBRKsh4Vmjrqk",
      });
    } else if (this.state.id == "3") {
      this.setState({
        urlGames:
          "https://kubuku.id/api/wl/transitUrl/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MTcwOTA2NjgsImV4cCI6MTcxNzE3NzA2OCwiaWRfdXNlciI6IjIxMjM2IiwiaWRfbWl0cmEiOiI1MzciLCJkZXN0X3VybCI6Imh0dHBzOlwvXC9rdWJ1a3UuaWRcL2dhbWVzXC9mcnVpdCJ9.5nZy-k85eKwJ9rpmuYwnIHQ9WKHZiatEPf_Yy3wIMfo",
      });
    } else if (this.state.id == "4") {
      this.setState({
        urlGames:
          "https://kubuku.id/api/wl/transitUrl/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MTcwOTA2NjgsImV4cCI6MTcxNzE3NzA2OCwiaWRfdXNlciI6IjIxMjM2IiwiaWRfbWl0cmEiOiI1MzciLCJkZXN0X3VybCI6Imh0dHBzOlwvXC9rdWJ1a3UuaWRcL2dhbWVzXC9hZGRfbnVtYmVyIn0.QE17rHgf31Hyxa2UC6xB_Iq3IbuEM7OBXL48UHl1LSE",
      });
    } else if (this.state.id == "5") {
      this.setState({
        urlGames:
          "https://kubuku.id/api/wl/transitUrl/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MTcwOTA2NjgsImV4cCI6MTcxNzE3NzA2OCwiaWRfdXNlciI6IjIxMjM2IiwiaWRfbWl0cmEiOiI1MzciLCJkZXN0X3VybCI6Imh0dHBzOlwvXC9wcmV2aWV3LmNvZGVjYW55b24ubmV0XC9pdGVtXC9xdWl6LWJveGluZy1odG1sNS1nYW1lLWNvbnN0cnVjdC0zXC9mdWxsX3NjcmVlbl9wcmV2aWV3XC80MDAxODEyMj9fZ2E9Mi4yNTg5NDkwLjM4MDg0MTI4Mi4xNjY0ODYyNDA5LTExNDgzMjY0MTQuMTY2MjczNjk2NyJ9.-qx5epjIHAMz0MQ5RLWHgTx7sAdVix-FXA8kbvvmW3U",
      });
    } else {
      this.setState({ urlGames: "https://titikbaca.id" });
    }
  };

  getRSS = async () => {
    try {
      let json = await executeGet(
        "https://kubuku.id/api/titikBaca/downloadRSSDetail/" +
          this.props.params.id
      );
      this.setState({
        news: json,
        flagBerita: true,
      });
    } catch (error) {
      this.setState({ isError: true, errorMessage: "Network Error!" });
    }
  };

  render() {
    return (
      <div>
        <nav className="fixed top-0 w-full z-10 shadow-md">
          <div
            onClick={() => this.props.navigate("/listgames")}
            className={
              "flex p-4 " +
              this.state.color +
              " text-lg text-white font-semibold items-center"
            }
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mr-8"
            >
              <path
                d="M15 6L9 12L15 18"
                stroke="white"
                strokeWidth="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Permainan Edukasi
          </div>
        </nav>
        <div className="mt-10" style={{ height: "calc(100vh - 10px" }}>
          <iframe src={this.state.urlGames} className="w-full h-full"></iframe>
        </div>
      </div>
    );
  }
}

export default WithNavigate;
