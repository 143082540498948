import React from "react";
import { Routes, Route } from "react-router-dom";
import Splash from "./Splash";
import Home from "./Home";
import News from "./News";
import GPSLocation from "./GPSLocation";
import Radio from "./Radio";
import DetailBooks from "./DetailBook";
import DetailRadio from "./RadioDetail";
import DetailNews from "./NewsDetail";
import QRScanner from "./QRScanner";
import KategoriBuku from "./KategoriBuku";
import RssDetail from "./RssDetail";
import ReadingBook from "./ReadingBook";
import ListBuku from "./ListBuku";
import ListYoutube from "./Youtube";
import ListRSS from "./ListRSS";
import BacaBerita from "./NewsViewer";
import AudioPlayer from "./AudioPlayer";
import LiterasiKeluarga from "./LiterasiKeluarga";
import InputKTP from "./InputKTP";
import GamesPage from "./Games";
import ListGames from "./ListGames";
import ContentCreator from "./ContentCreator";
import ListMusik from "./ListMusik";
import MusikDetail from "./MusikDetail";
import Kementrian from "./Kementrian";
import ListKementerian from "./ListKementerian";
import MiniBrowser from "./MiniBrowser";
import Settings from "./Settings";
import DetailBooksOffline from "./DetailBookOffline";
import ReadingBookOffline from "./ReadingBookOffline";
import VideoTutorial from "../components/VideoTutorial";

export default function index() {
  return (
    <Routes>
      <Route exact path="/" element={<Splash />} />
      <Route exact path="/splash" element={<Splash />} />
      <Route exact path="/listmusik" element={<ListMusik />} />
      <Route exact path="/detailmusik/:id" element={<MusikDetail />} />
      <Route exact path="/:alias" element={<Splash />} />
      <Route exact path="/home/:alias" element={<Home />} />
      <Route exact path="/news" element={<News />} />
      <Route exact path="/kementerian/:alias" element={<Kementrian />} />
      <Route
        exact
        path="/listkementerian/:id/:alias"
        element={<ListKementerian />}
      />
      <Route exact path="/contentcreator" element={<ContentCreator />} />
      <Route exact path="/games/:id" element={<GamesPage />} />
      <Route
        exact
        path="/minibrowser/:id/:alias/:kementerian/:url"
        element={<MiniBrowser />}
      />
      <Route exact path="/listgames" element={<ListGames />} />
      <Route exact path="/audioplayer/:id" element={<AudioPlayer />} />
      <Route exact path="/audioplayerKeluarga/:id" element={<AudioPlayer />} />
      <Route exact path="/rss/:id" element={<RssDetail />} />
      <Route
        exact
        path="/sectionkeluarga/:token"
        element={<LiterasiKeluarga />}
      />
      <Route exact path="/location" element={<GPSLocation />} />
      <Route exact path="/radio" element={<Radio />} />
      <Route exact path="/inputktp/:tipe/:alias" element={<InputKTP />} />
      <Route exact path="/qrscanner" element={<QRScanner />} />
      <Route exact path="/listBuku/:tipe/:alias" element={<ListBuku />} />
      <Route
        exact
        path="/detail/:tipe/:id/:alias/:token"
        element={<DetailBooks />}
      />
      <Route
        exact
        path="/detailKeluarga/:tipe/:id/:alias/:token"
        element={<DetailBooks />}
      />
      <Route
        exact
        path="/bacaBuku/:id/:alias/:judul/:tipe/:token"
        element={<ReadingBook />}
      />
      <Route
        exact
        path="/bacaBukuKeluarga/:id/:alias/:judul/:tipe/:token"
        element={<ReadingBook />}
      />
      <Route
        exact
        path="/bacaBerita/:alias/:id/:tanggal"
        element={<BacaBerita />}
      />
      <Route exact path="/videoTutorial/:id" element={<VideoTutorial />} />
      <Route exact path="/detailBerita/:id/:alias" element={<DetailNews />} />
      <Route exact path="/detailRadio/:id" element={<DetailRadio />} />
      <Route exact path="/ytb/:id" element={<ListYoutube />} />
      <Route exact path="/listrss/:id" element={<ListRSS />} />
      <Route
        exact
        path="/kategori/:kategori/:alias"
        element={<KategoriBuku />}
      />
      <Route
        exact
        path="/section/:detailBuku/:konten"
        element={<DetailBooksOffline />}
      />
      <Route
        exact
        path="/bacaBukuOffline/:id/:alias/:judul/:jenis/:type"
        element={<ReadingBookOffline />}
      />
      <Route exact path="/menuSetting" element={<Settings />} />
    </Routes>
  );
}
