import { Component } from "react";

class SkeletonBookDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
    };
  }

  componentDidMount = async () => {};

  render() {
    return (
      <div>
        <div className="relative mt-[56px] px-4 py-6 h-56 bg-gray-300 rounded-bl-3xl">
          <div className="absolute w-44 h-60 bg-gray-200 left-[27%] sm:left-[37%] md:left-[40%] rounded-2xl shadow-md animate-pulse"></div>
        </div>
        <div className="px-4">
          <div className="mt-[18%] mb-8 animate-pulse">
            <div className="w-56 h-4 bg-gray-300 rounded-full mx-auto"></div>
            <div className="w-64 h-4 mt-2 bg-gray-300 rounded-full mx-auto"></div>
            <div className="w-20 h-4 mt-2 bg-gray-300 rounded-full mx-auto"></div>
            <div className="w-32 h-4 mt-2 bg-gray-300 rounded-full mx-auto"></div>
          </div>
          <div className="animate-pulse">
            <div className="w-32 h-4 mt-5 mb-1.5 px-4 bg-gray-300 rounded-full"></div>
            <div className="w-64 h-4 mt-5 bg-gray-300 rounded-full"></div>
            <div className="w-56 h-4 mt-2 bg-gray-300 rounded-full"></div>
            <div className="w-32 h-4 mt-4 bg-gray-300 rounded-full ml-auto"></div>
          </div>
        </div>
        <div className="fixed bottom-0 left-0 w-full bg-white z-20 p-3 drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]">
          <div className="w-full h-12 bg-gray-300 rounded-2xl animate-pulse"></div>
        </div>
      </div>
    );
  }
}

export default SkeletonBookDetail;
