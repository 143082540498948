import React, { Component, useState } from "react";
import { executeGet, goBack } from "../functions/Kubuku";
import QrReader from "react-qr-scanner";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";
import { CEK_ONLINE } from "../api";

const QR = () => {
  const [delay, setDelay] = useState(100);
  const [result, setResult] = useState("No result");

  const previewStyle = {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  };

  const handleScan = (data) => {
    if (data) {
      setResult(data.text); // Menggunakan data.text untuk menangani hasil scan QR
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const isUrl = (text) => {
    // Sederhana untuk memeriksa apakah hasil QR code adalah URL
    const urlPattern = /^(https?:\/\/[^\s$.?#].[^\s]*)$/i;
    return urlPattern.test(text);
  };

  return (
    <>
      <QrReader
        delay={delay}
        style={previewStyle}
        onScan={handleScan}
        onError={handleError}
        constraints={{
          video: {
            facingMode: "environment", // Menggunakan kamera belakang
          },
        }}
      />
      {isUrl(result) && (window.location.href = result)}
    </>
  );
};

class QRScanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: window.location.pathname,
      url: "",
      showModal: false,
      online: "0",
    };
  }

  componentDidMount = async () => {
    //this.loadRSS();
    this.setState({
      url: "https://pnri.titikbaca.id/" + window.localStorage.getItem("alias"),
    });
    this.getCekOnline();
  };

  handleAdditionalAction = () => {
    this.setState({
      showModal: true,
    });
  };

  getCekOnline = async () => {
    try {
      const response = await executeGet(CEK_ONLINE);
      if (response.code == 200) {
        this.setState({
          online: response.data.auto,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  closeModal = () => {
    this.setState({
      showModal: false,
    });
  };

  render() {
    return (
      <div>
        <nav className="fixed top-0 w-full z-10 shadow-md">
          <div className="flex justify-between w-full bg-orange">
            <div
              onClick={() => goBack()}
              className="flex p-4 text-lg text-white font-semibold items-center"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mr-8"
              >
                <path
                  d="M15 6L9 12L15 18"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              QR Scanner
            </div>
            {localStorage.getItem("sn") && localStorage.getItem("guid") && (
              <div
                onClick={() => this.handleAdditionalAction()}
                className="flex p-4 items-center cursor-pointer"
              >
                <img
                  src="/video.png" // Ganti dengan path atau URL ikon PNG Anda
                  alt="Additional Icon"
                  className="w-8 h-8  bg-white rounded-lg"
                />
              </div>
            )}
          </div>
        </nav>

        {this.state.showModal && (
          <div
            onClick={() => this.closeModal()} // Tutup modal ketika klik di luar konten
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-20"
          >
            <div
              onClick={(e) => e.stopPropagation()} // Mencegah penutupan modal jika klik di dalam konten modal
              className="bg-white rounded-lg p-6 w-80"
            >
              {/* Ikon tombol dalam modal */}
              <div className="flex justify-around mt-4">
                <div className="flex flex-col items-center">
                  <img
                    src="/youtube.png" // Ganti dengan path ikon pertama
                    alt="Icon 1"
                    className="w-14 h-14 cursor-pointer"
                    onClick={() =>
                      window.open(
                        "https://www.youtube.com/@PerpustakaanNasionalRI",
                        "_blank"
                      )
                    }
                  />
                  <span className="text-sm mt-2">YouTube</span>{" "}
                  {/* Deskripsi ikon */}
                </div>
                <div className="flex flex-col items-center">
                  <img
                    src="/setting.png" // Ganti dengan path ikon kedua
                    alt="Icon 2"
                    className="w-14 h-14 cursor-pointer"
                    onClick={() => {
                      window.location.href = "/menuSetting"; // Menggunakan window.location untuk navigasi
                    }}
                  />
                  <span className="text-sm mt-2">Settings</span>{" "}
                  {/* Deskripsi ikon */}
                </div>
                <div className="flex flex-col items-center">
                  <img
                    src="/internet.png" // Ganti dengan path ikon kedua
                    alt="Icon 2"
                    className="w-14 h-14 cursor-pointer"
                    onClick={() => {
                      window.location.href = "/menuSetting"; // Menggunakan window.location untuk navigasi
                    }}
                  />
                  <span className="text-sm mt-2">Online</span>{" "}
                  {/* Deskripsi ikon */}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="mx-2 mt-10 p-2">
          <div className="mx-4 mt-8 flex justify-center">
            {localStorage.getItem("sn") && localStorage.getItem("guid") ? (
              <QRCode
                className="bg-white p-2 rounded mt-60"
                size={70}
                style={{
                  height: "auto",
                  maxWidth: "150px",
                  width: "150px",
                }}
                value={this.state.url}
                viewBox={`0 0 90 90`}
              />
            ) : (
              <QR />
            )}
          </div>
          <center className="text-xl">
            {localStorage.getItem("sn") && localStorage.getItem("guid") ? (
              <h3>Barcode Titikbaca {localStorage.getItem("nama")}</h3>
            ) : (
              <h3>Arahkan kamera ke QR Code Titikbaca</h3>
            )}
          </center>
        </div>
      </div>
    );
  }
}

export default QRScanner;
