import React, { Component, useEffect } from "react";
import ReactAudioPlayer from "react-audio-player";
import ReactPlayer from "react-player";
import { useParams, useNavigate } from "react-router-dom";
import { executeGet, goBack } from "../functions/Kubuku";
import { BASE_URL, RETURN_AUDIO_PLAYER } from "../api";
function WithNavigate(props) {
  let navigate = useNavigate();
  return <AudioPlayer {...props} navigate={navigate} params={useParams()} />;
}

class AudioPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      showMain: false,
      params: this.props.params,
      dataBuku: [],
      alias: window.localStorage.getItem("alias"),
      lokasi: "",
      lat: "-7.557130246009252",
      long: "110.79448368501073",
      alt: "1",
      flagData: false,
      audioList: [],
      hal: 1,
      color: window.localStorage.getItem("color"),
      darkcolor: "bg-orang-dark",
      textcolor: "text-orange",
      firstRun: true,
      uuid: "",
      idTrxAudioPlayer: 0,
    };
  }

  componentDidMount = async () => {
    if (this.state.firstRun) {
      this.setState({
        alias: window.localStorage.getItem("alias"),
        uuid: localStorage.getItem("uuid"),
      });
      this.getTheme();
      this.getLocation();
    }
  };

  getLocation = () => {
    if (window.localStorage.getItem("sn") != null) {
      this.loadData(
        window.localStorage.getItem("lat"),
        window.localStorage.getItem("long"),
        window.localStorage.getItem("alt")
      );
    } else {
      navigator.geolocation.getCurrentPosition((position) => {
        let long = Number(position.coords.longitude);
        let lat = Number(position.coords.latitude);
        let alt = position.coords.altitude;
        this.setState({
          lat: lat,
          long: long,
          alt: alt,
        });
        this.loadData(lat, long, alt);
      });
    }
  };

  loadData = async (lat, long, alt) => {
    try {
      var json = await executeGet(
        BASE_URL +
          "detailContent/" +
          this.props.params.id +
          "/" +
          this.state.alias +
          "?uuid=" +
          localStorage.getItem("uuid")
      );
      if (json.code == "200") {
        this.setState({
          dataBuku: json.data,
          audioList: json.multimedia,
          flagData: true,
          isLoading: false,
          idTrxAudioPlayer: json.id_trx_baca,
        });
      } else {
        this.setState({
          isLoading: false,
          flagData: false,
        });
      }
    } catch (error) {
      this.setState({ isError: true, errorMessage: "Network Error!" });
    }
  };

  returnAudioPlayer = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      const response = await executeGet(
        RETURN_AUDIO_PLAYER +
          this.state.idTrxAudioPlayer +
          "/" +
          localStorage.getItem("alias")
      );
      if (response.code == 200) {
        this.setState({
          isLoading: true,
        });
        goBack();
      }
    } catch (error) {
      console.error(error);
    }
  };

  getTheme = async () => {
    try {
      if (window.localStorage.getItem("themes") != null) {
        const ljson = window.localStorage.getItem("themes");
        const json = JSON.parse(ljson);
        if (json.code == 200) {
          if (json.warna != "") {
            this.setState({
              color: json.warna,
            });
          }
          if (json.dark != "") {
            this.setState({
              darkcolor: json.dark,
            });
          }
          if (json.text != "") {
            this.setState({
              textcolor: json.text,
            });
          }
        }
      }
    } catch (error) {
      this.setState({ isError: true, errorMessage: "Network Error!" });
    }
  };
  render() {
    return (
      <div style={{ backgroundColor: "#ecf0f1" }}>
        <nav className="fixed top-0 w-full z-10 shadow-md">
          <div
            onClick={() => this.returnAudioPlayer()}
            className={
              "flex p-4 " +
              this.state.color +
              " text-lg text-white font-semibold items-center"
            }
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mr-8"
            >
              <path
                d="M15 6L9 12L15 18"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span className="line-clamp-1 ">Player</span>
          </div>
        </nav>
        {this.state.flagData && (
          <>
            <div
              style={{
                flexGrow: "1",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#ecf0f1",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: 320,
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#184999",
                  position: "sticky",
                  top: 0,
                  left: 0,
                  right: 0,
                }}
              >
                <div
                  className={"relative mt-[56px] px-4 py-6 h-8  rounded-bl-3xl"}
                >
                  <img
                    src={this.state.dataBuku.cover}
                    alt="cover buku"
                    className="absolute w-32 h-32 left-[35%] sm:left-[45%] md:left-[40%] rounded-full shadow-md"
                  />

                  <h3 className="text-center mt-40 text-xl font-bold text-slate-200 line-clamp-2">
                    {this.state.dataBuku.judul}
                  </h3>
                  <h4 className="text-sm text-center text-slate-400 line-clamp-1">
                    {this.state.dataBuku.penulis}
                  </h4>
                </div>
              </div>
              <div className="z-0 relative mt-[32px] mb-[70px] sm:mb-0 sm:mt-[30px] md:mb-[28px] bg-white before:absolute before:top-[-25px] before:left-0 before:w-full before:h-[25px] before:bg-white before:rounded-t-2xl after:absolute after:w-20 after:h-1 after:top-[-14px] after:left-[38%] after:sm:left-[44%] after:bg-gray-200 after:rounded-full">
                <div
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    mt: 4,
                    mb: 4,
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "white",
                  }}
                >
                  {this.state.audioList.map((item, index) => (
                    <div
                      key={"plyr_" + index}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        mt: 2,
                      }}
                    >
                      <div>{item.judul}</div>
                      {item.file.indexOf(".mp4") > 0 ? (
                        <ReactPlayer
                          controls={true}
                          width={"95vw"}
                          height={"30vh"}
                          config={{
                            file: {
                              attributes: { controlsList: "nodownload" },
                            },
                          }}
                          url={item.file}
                        />
                      ) : (
                        <ReactAudioPlayer
                          src={item.file}
                          style={{
                            width: "95vw",
                            borderRadius: "5px",
                            backgroundColor: "#f1f3f4",
                          }}
                          controls
                          controlsList={"nodownload"}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}
export default WithNavigate;
