import Glider from "react-glider";
import loaderGif from "../assets/images/loader.gif";
import { Component, useEffect, useState } from "react";

const SkeletonLocation = () => {
  return (
    <div className="flex items-center animate-pulse">
      <div className="rounded-full p-4 w-8 h-8 bg-gray-300  shadow-md hover:shadow-lg duration-150 ease-out cursor-pointer"></div>
      <div className="w-52 h-3 bg-gray-300 rounded-full ml-2"></div>
    </div>
  );
};

const SkeletonHomePage = (props) => {
  return (
    <>
      <div className="flex justify-between px-4 items-center mt-4 mb-2 animate-pulse">
        <div className="w-28 h-4 bg-gray-300 rounded-full"></div>
        <div className="w-20 h-4 bg-gray-300 rounded-full"></div>
      </div>
      <div
        className={
          props.mode
            ? "pl-4 md:hidden mb-20 sm:block "
            : "pl-4 md:hidden sm:block "
        }
      >
        <Glider
          draggable
          slidesToShow={3.1}
          slidesToScroll={1}
          className="book-popular"
        >
          {Array.from({ length: 10 }).map((_, index) => (
            <div className="p-1 pr-2" key={index}>
              <div className="rounded-xl shadow-md hover:shadow-lg duration-150 ease-out cursor-pointer">
                <div className="h-[180px] overflow-hidden animate-pulse">
                  <div className="w-full h-[200px] bg-gray-300 rounded-xl"></div>
                </div>
                <div className="p-2 min-h-[120px] animate-pulse">
                  <div className="w-full h-3 mt-1 bg-gray-300 rounded-full"></div>
                  <div className="w-full h-3 mt-3 bg-gray-300 rounded-full"></div>
                  <div className="w-full h-3 mt-3 bg-gray-300 rounded-full"></div>
                </div>
              </div>
            </div>
          ))}
        </Glider>
      </div>
      <div
        className={
          props.mode
            ? "pl-4 md:block mb-20 sm:hidden "
            : "pl-4 md:block sm:hidden "
        }
      >
        <Glider
          draggable
          slidesToShow={3.1}
          slidesToScroll={1}
          className="book-popular"
        >
          {Array.from({ length: 10 }).map((_, index) => (
            <div className="p-1 pr-2" key={index}>
              <div className="rounded-xl shadow-md hover:shadow-lg duration-150 ease-out cursor-pointer">
                <div className="h-[180px] overflow-hidden animate-pulse">
                  <div className="w-full h-[200px] bg-gray-300 rounded-xl"></div>
                </div>
                <div className="p-2 min-h-[120px] animate-pulse">
                  <div className="w-full h-3 mt-1 bg-gray-300 rounded-full"></div>
                  <div className="w-full h-3 mt-3 bg-gray-300 rounded-full"></div>
                  <div className="w-full h-3 mt-3 bg-gray-300 rounded-full"></div>
                </div>
              </div>
            </div>
          ))}
        </Glider>
      </div>
    </>
  );
};

const SkeletonCategory = () => {
  return (
    <div className="grid grid-cols-3 gap-2 mt-4 px-4 animate-pulse">
      <div className="w-full h-12 bg-gray-300 rounded-lg"></div>
      <div className="w-full h-12 bg-gray-300 rounded-lg"></div>
      <div className="w-full h-12 bg-gray-300 rounded-lg"></div>
      <div className="w-full h-12 bg-gray-300 rounded-lg"></div>
      <div className="w-full h-12 bg-gray-300 rounded-lg"></div>
      <div className="w-full h-12 bg-gray-300 rounded-lg"></div>
    </div>
  );
};

const SkeletonBeritaKominfo = () => {
  return (
    <>
      <div className="flex justify-between px-4 items-center mt-4 md:mt-6 mb-2 animate-pulse">
        <div className="w-28 h-7 bg-gray-200 rounded-full"></div>
        <div className="w-20 h-4 bg-gray-200 rounded-full"></div>
      </div>
      <div className="px-4 md:flex md:items-center">
        <div className="lg:w-7/12">
          <div className="md:grid md:grid-cols-2">
            {Array.from({ length: 4 }).map((_, index) => (
              <div
                key={index}
                className="flex mb-2.5 md:mx-2 p-2 bg-white rounded-xl shadow-md border border-gray-100 border-opacity-100 animate-pulse"
              >
                <div className="w-[90px] h-[90px] bg-gray-200 mr-3 rounded-xl"></div>
                <div className="w-8/12">
                  <div className="w-full h-3 bg-gray-200 rounded-full"></div>
                  <div className="mt-2 w-3/4 h-3 bg-gray-200 rounded-full"></div>
                  <div className="mt-4 w-20 h-3 bg-gray-200 rounded-full"></div>
                  <div className="mt-2 w-24 h-3 bg-gray-200 rounded-full"></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const SkeletonHeadBerita = () => {
  return (
    <div className="px-4 md:flex md:items-center">
      <div className="md:hidden lg:block md:w-5/12 lg:w-5/12 md:mr-2 mb-2.5 h-[228px] bg-gray-200 rounded-xl shadow-md relative animate-pulse">
        <div className="w-full max-h-32 p-4 absolute bottom-0 left-0">
          <div className="w-full h-5 bg-gray-300 rounded-full"></div>
          <div className="mt-2 flex">
            <div className="w-8 h-8 bg-gray-300 overflow-hidden rounded-full"></div>
            <div className="ml-2 space-y-2">
              <div className="w-16 h-3 bg-gray-300 rounded-full"></div>
              <div className="w-20 h-3 bg-gray-300 rounded-full"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SkeletonBerita = () => {
  return (
    <>
      <div className="flex justify-between px-4 items-center mt-4 md:mt-6 mb-2 animate-pulse">
        <div className="w-28 h-7 bg-gray-200 rounded-full"></div>
        <div className="w-20 h-4 bg-gray-200 rounded-full"></div>
      </div>
      <div className="px-4 md:flex md:items-center">
        <div className="md:hidden lg:block md:w-5/12 lg:w-5/12 md:mr-2 mb-2.5 h-[228px] bg-gray-200 rounded-xl shadow-md relative animate-pulse">
          <div className="w-full max-h-32 p-4 absolute bottom-0 left-0">
            <div className="w-full h-5 bg-gray-300 rounded-full"></div>
            <div className="mt-2 flex">
              <div className="w-8 h-8 bg-gray-300 overflow-hidden rounded-full"></div>
              <div className="ml-2 space-y-2">
                <div className="w-16 h-3 bg-gray-300 rounded-full"></div>
                <div className="w-20 h-3 bg-gray-300 rounded-full"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:w-7/12">
          <div className="md:grid md:grid-cols-2">
            {Array.from({ length: 4 }).map((_, index) => (
              <div
                key={index}
                className="flex mb-2.5 md:mx-2 p-2 bg-white rounded-xl shadow-md border border-gray-100 border-opacity-100 animate-pulse"
              >
                <div className="w-[90px] h-[90px] bg-gray-200 mr-3 rounded-xl"></div>
                <div className="w-8/12">
                  <div className="w-full h-3 bg-gray-200 rounded-full"></div>
                  <div className="mt-2 w-3/4 h-3 bg-gray-200 rounded-full"></div>
                  <div className="mt-4 w-20 h-3 bg-gray-200 rounded-full"></div>
                  <div className="mt-2 w-24 h-3 bg-gray-200 rounded-full"></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const SkeletonHalamanBerita = () => {
  return (
    <>
      <div className="lg:w-7/12">
        <div className="md:grid md:grid-cols-2">
          {Array.from({ length: 4 }).map((_, index) => (
            <div
              key={index}
              className="flex mb-2.5 md:mx-2 p-2 bg-white rounded-xl shadow-md border border-gray-100 border-opacity-100 mt-5 animate-pulse"
            >
              <div className="w-[90px] h-[90px] bg-gray-200 mr-3 rounded-xl"></div>
              <div className="w-8/12">
                <div className="w-full h-3 bg-gray-200 rounded-full"></div>
                <div className="mt-2 w-3/4 h-3 bg-gray-200 rounded-full"></div>
                <div className="mt-4 w-20 h-3 bg-gray-200 rounded-full"></div>
                <div className="mt-2 w-24 h-3 bg-gray-200 rounded-full"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

const SkeletonTitikBaca = () => {
  return (
    <>
      <div className="flex justify-between px-4 items-center mt-4 md:mt-6 mb-2 animate-pulse">
        <div className="w-28 h-7 bg-gray-200 rounded-full"></div>
      </div>
      <div className="my-6 px-4 md:hidden sm:block ">
        <div className="grid grid-cols-3 gap-4">
          {Array.from({ length: 3 }).map((_, index) => (
            <div className="text-center animate-pulse" key={index}>
              <div className="mx-auto rounded-full p-4 w-16 h-16 bg-gray-300  shadow-md hover:shadow-lg duration-150 ease-out cursor-pointer"></div>
              <div className="mx-auto w-28 h-3 mt-3 bg-gray-300 rounded-full"></div>
              <div className="mx-auto w-24 h-3 mt-3 bg-gray-300 rounded-full"></div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

const SkeletonBukuTerlaris = () => {
  return (
    <>
      <div className="flex justify-between px-4 items-center mt-4 md:mt-6 mb-2 animate-pulse">
        <div className="w-28 h-7 bg-gray-200 rounded-full"></div>
        <div className="w-20 h-4 bg-gray-200 rounded-full"></div>
      </div>
      <div className="px-4 md:flex md:items-center">
        <div className="lg:w-7/12">
          <div className="md:grid md:grid-cols-3">
            {Array.from({ length: 6 }).map((_, index) => (
              <div
                key={index}
                className="flex mb-2.5 md:mx-2 p-2 bg-white rounded-xl shadow-md border border-gray-100 border-opacity-100 animate-pulse"
              >
                <div className="w-28 h-40 bg-gray-200 mr-3 rounded-xl"></div>
                <div className="w-8/12">
                  <div className="w-full h-3 bg-gray-200 rounded-full"></div>
                  <div className="mt-2 w-3/4 h-3 bg-gray-200 rounded-full"></div>
                  <div className="mt-4 w-3/4 h-3 bg-gray-200 rounded-full"></div>
                  <div className="mt-2 w-3/4 h-3 bg-gray-200 rounded-full"></div>
                  <div className="mt-2 w-3/4 h-3 bg-gray-200 rounded-full"></div>
                  <div className="mt-2 w-3/4 h-3 bg-gray-200 rounded-full"></div>
                  <div className="mt-4 w-24 h-3 bg-gray-200 rounded-full"></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const SkeletonVideo = () => {
  return (
    <>
      <div className="flex justify-between px-4 items-center mt-4 md:mt-6 mb-2 animate-pulse">
        <div className="w-28 h-7 bg-gray-200 rounded-full"></div>
        <div className="w-20 h-4 bg-gray-200 rounded-full"></div>
      </div>
      <div className="pl-4 md:hidden sm:block ">
        <Glider
          draggable
          slidesToShow={1.1}
          slidesToScroll={1}
          className="book-popular"
        >
          {Array.from({ length: 10 }).map((_, index) => (
            <div className="p-1 pr-2" key={index}>
              <div className="rounded-xl shadow-md hover:shadow-lg duration-150 ease-out cursor-pointer">
                <div className="h-[170px] overflow-hidden animate-pulse">
                  <div className="w-full h-[200px] bg-gray-300 rounded-xl"></div>
                </div>
                <div className="flex p-2.5 min-h-[96px] animate-pulse items-center">
                  <div className="w-2/12">
                    <div className="w-11 h-11 overflow-hidden rounded-full border-2 border-slate-300">
                      <div className="rounded-full p-4 w-16 h-16 bg-gray-300  shadow-md hover:shadow-lg duration-150 ease-out cursor-pointer"></div>
                    </div>
                  </div>
                  <div className="w-10/12">
                    <div className="w-full h-3  bg-gray-300 rounded-full"></div>
                    <div className="w-full h-3 mt-2 bg-gray-300 rounded-full"></div>
                    <div className="w-24 h-3 mt-4 bg-gray-300 rounded-full"></div>
                    <div className="w-16 h-3 mt-2 bg-gray-300 rounded-full"></div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Glider>
      </div>
      <div className="pl-4 md:block hidden ">
        <Glider
          draggable
          slidesToShow={1.1}
          slidesToScroll={1}
          className="book-popular"
        >
          {Array.from({ length: 10 }).map((_, index) => (
            <div className="p-1 pr-2" key={index}>
              <div className="rounded-xl shadow-md hover:shadow-lg duration-150 ease-out cursor-pointer">
                <div className="h-[170px] overflow-hidden animate-pulse">
                  <div className="w-full h-[200px] bg-gray-300 rounded-xl"></div>
                </div>
                <div className="flex p-2.5 min-h-[96px] animate-pulse items-center">
                  <div className="w-2/12">
                    <div className="w-11 h-11 overflow-hidden rounded-full border-2 border-slate-300">
                      <div className="rounded-full p-4 w-16 h-16 bg-gray-300  shadow-md hover:shadow-lg duration-150 ease-out cursor-pointer"></div>
                    </div>
                  </div>
                  <div className="w-10/12">
                    <div className="w-full h-3  bg-gray-300 rounded-full"></div>
                    <div className="w-full h-3 mt-2 bg-gray-300 rounded-full"></div>
                    <div className="w-24 h-3 mt-4 bg-gray-300 rounded-full"></div>
                    <div className="w-16 h-3 mt-2 bg-gray-300 rounded-full"></div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Glider>
      </div>
    </>
  );
};

const SkeletonBanyakDibacaTothem = (props) => {
  const [slidesToShow, setSlidesToShow] = useState(2.4);

  useEffect(() => {
    const mediaQuery = window.matchMedia(
      "(max-width: 720px) and (max-height: 1280px)"
    );

    const handleMediaQueryChange = (event) => {
      if (event.matches) {
        setSlidesToShow(6.0);
      } else {
        setSlidesToShow(2.4);
      }
    };

    // Set initial value
    handleMediaQueryChange(mediaQuery);

    // Add event listener
    mediaQuery.addEventListener("change", handleMediaQueryChange);

    // Cleanup event listener
    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  return (
    <>
      <div className="flex justify-between px-4 items-center mt-4 mb-2 animate-pulse">
        <div className="w-28 h-4 bg-gray-300 rounded-full"></div>
        <div className="w-20 h-4 bg-gray-300 rounded-full"></div>
      </div>
      <div
        className={
          props.mode
            ? "pl-4 md:hidden mb-20 sm:block "
            : "pl-4 md:hidden sm:block "
        }
      >
        <Glider
          draggable
          slidesToShow={slidesToShow}
          slidesToScroll={1}
          className="book-popular"
        >
          {Array.from({ length: 10 }).map((_, index) => (
            <div className="p-1 pr-2" key={index}>
              <div className="rounded-xl shadow-md hover:shadow-lg duration-150 ease-out cursor-pointer">
                <div className="h-[180px] overflow-hidden animate-pulse">
                  <div className="w-full h-[200px] bg-gray-300 rounded-xl"></div>
                </div>
                <div className="p-2 min-h-[120px] animate-pulse">
                  <div className="w-28 sm:w-[5rem] h-3 mt-1 bg-gray-300 rounded-full"></div>
                  <div className="w-24 sm:w-[4rem] h-3 mt-3 bg-gray-300 rounded-full"></div>
                  <div className="w-16 sm:w-[3rem] h-3 mt-3 bg-gray-300 rounded-full"></div>
                </div>
              </div>
            </div>
          ))}
        </Glider>
      </div>
    </>
  );
};

const SkeletonBanyakDibaca = (props) => {
  return (
    <>
      {props.mode !== "radio" && (
        <div className="flex justify-between px-4 items-center mt-4 md:mt-6 mb-2 animate-pulse">
          <div className="w-28 h-7 bg-gray-200 rounded-full"></div>
          <div className="w-20 h-4 bg-gray-200 rounded-full"></div>
        </div>
      )}
      <>
        <div
          className={
            props.mode == "radio"
              ? "p-4 mt-16 mb-10 bg-white"
              : "md:mt-16 mb-[100px] bg-white"
          }
        >
          <div className="px-4 grid grid-cols-2 md:grid-cols-4 gap-4 animate-pulse">
            {/* <!-- Item Book --> */}
            {Array.from({ length: 4 }).map((_, index) => (
              <div key={index}>
                <div
                  className="h-[325px] bg-white rounded-xl shadow-md hover:shadow-lg duration-150 ease-out cursor-pointer"
                  data-te-ripple-init
                  data-te-ripple-color="secondary"
                >
                  <div className="h-[205px] w-full bg-gray-300 rounded-xl overflow-hidden"></div>
                  <div className="p-2">
                    <div className="title-book mt-3 w-full h-3 bg-gray-300 rounded-full"></div>
                    <div className="author mt-2 w-24 h-3 bg-gray-300 rounded-full"></div>
                    <div className="publisher mt-2 w-full h-3 bg-gray-300 rounded-full"></div>
                    <div className="stock mt-2 w-24 h-3 bg-gray-300 rounded-full"></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    </>
  );
};

const SkeletonHalaman = (props) => {
  return (
    <>
      {props.mode !== "radio" && (
        <div className="flex justify-between px-4 items-center mt-4 md:mt-6 mb-2 animate-pulse">
          <div className="w-28 h-7 bg-gray-200 rounded-full"></div>
          <div className="w-20 h-4 bg-gray-200 rounded-full"></div>
        </div>
      )}
      <>
        <div
          className={
            props.mode == "radio"
              ? "p-4 mt-16 mb-10 bg-white"
              : "md:mt-16 mb-[100px] bg-white"
          }
        >
          <div className="px-4 grid grid-cols-2 md:grid-cols-4 gap-4 animate-pulse">
            {/* <!-- Item Book --> */}
            {Array.from({ length: 8 }).map((_, index) => (
              <div key={index}>
                <div
                  className="h-[325px] bg-white rounded-xl shadow-md hover:shadow-lg duration-150 ease-out cursor-pointer"
                  data-te-ripple-init
                  data-te-ripple-color="secondary"
                >
                  <div className="h-[205px] w-full bg-gray-300 rounded-xl overflow-hidden"></div>
                  <div className="p-2">
                    <div className="title-book mt-3 w-full h-3 bg-gray-300 rounded-full"></div>
                    <div className="author mt-2 w-24 h-3 bg-gray-300 rounded-full"></div>
                    <div className="publisher mt-2 w-full h-3 bg-gray-300 rounded-full"></div>
                    <div className="stock mt-2 w-24 h-3 bg-gray-300 rounded-full"></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    </>
  );
};

const SkeletonMaps = () => (
  <div className="fixed top-0 w-full h-[600px] bg-gray-300 animate-pulse"></div>
);

const SkeletonLocationSaatIni = () => (
  <div className="flex mt-3 px-4 items-center animate-pulse">
    <div className="h-[45px] w-[45px] mr-4 bg-gray-300 rounded-full"></div>
    <div>
      <div className="w-28 h-3 bg-gray-300 rounded-full"></div>
      <div className="w-40 h-3 mt-2 bg-gray-300 rounded-full"></div>
    </div>
  </div>
);

const SkeletonLocationItems = () => (
  <div className="flex mb-4 px-4 items-center animate-pulse">
    <div className="h-[45px] w-[45px] mr-4 bg-gray-300 rounded-full"></div>
    <div>
      <div className="w-40 h-3 bg-gray-300 rounded-full"></div>
    </div>
  </div>
);

const SkeletonDetailBook = () => {
  return (
    <>
      <div className="relative mt-[56px] px-4 py-6 h-56 bg-gray-300 rounded-bl-3xl">
        <div className="absolute w-44 h-60 bg-gray-200 left-[27%] rounded-2xl shadow-md animate-pulse"></div>
      </div>
      <div className="px-4">
        <div className="mt-[18%] mb-8 animate-pulse">
          <div className="w-56 h-4 bg-gray-300 rounded-full mx-auto"></div>
          <div className="w-64 h-4 mt-2 bg-gray-300 rounded-full mx-auto"></div>
          <div className="w-20 h-4 mt-2 bg-gray-300 rounded-full mx-auto"></div>
          <div className="w-32 h-4 mt-2 bg-gray-300 rounded-full mx-auto"></div>
        </div>
        <div className="animate-pulse">
          <div className="w-32 h-4 mt-5 mb-1.5 px-4 bg-gray-300 rounded-full"></div>
          <div className="w-64 h-4 mt-5 bg-gray-300 rounded-full"></div>
          <div className="w-56 h-4 mt-2 bg-gray-300 rounded-full"></div>
          <div className="w-32 h-4 mt-4 bg-gray-300 rounded-full ml-auto"></div>
        </div>
      </div>
    </>
  );
};

const SkeletonBeritaHomePage = () => {
  return (
    <div>
      <div className="hidden lg:block">
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-[20%] h-[17%] mx-auto">
            {/*content*/}
            <div className="flex justify-center items-center h-screen">
              <div className="p-4 border-0 rounded-lg shadow-lg w-full bg-white outline-none focus:outline-none m-auto">
                <div className="justify-center items-center">
                  <img src={loaderGif} alt="loader" className="h-10 mx-auto" />
                </div>
                <div className="text-center mt-4 font-semiboldtext-black">
                  Tunggu Sebentar...
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </div>

      <div className="block lg:hidden">
        <div className="flex h-screen overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="w-[60%] h-[120px] mx-auto">
            {/*content*/}
            <div className="flex justify-center items-center h-screen">
              <div className="p-4 border-0 rounded-lg shadow-lg w-full bg-white outline-none focus:outline-none m-auto">
                <div className="justify-center items-center">
                  <img src={loaderGif} alt="loader" className="h-10 mx-auto" />
                </div>
                <div className="text-center mt-4 font-semibold text-black">
                  Tunggu Sebentar...
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </div>
    </div>
  );
};

class SkeletonRadioDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      floatMenu: false,
      width: 0,
      height: 0,
      flagPotraitPhone: false,
    };
  }

  componentDidMount = async () => {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
    // console.log('innerWidth = ' + window.innerWidth)
    // console.log('innerHeight = ' + window.innerHeight)
    if (window.innerWidth < 500 && window.innerHeight > 650) {
      // console.log("disini")
      this.setState({ flagPotraitPhone: true });
    } else if (window.innerWidth > 700 && window.innerHeight > 500) {
      // console.log("disini 1")
      this.setState({ flagPotraitPhone: true });
    } else {
      // console.log("disini 2")
      this.setState({ flagPotraitPhone: false });
    }
    // console.log('innerWidth = ' + this.state.width)
    // console.log('innerHeight = ' + this.state.height)
  };

  render() {
    return (
      <div>
        <div className="fixed left-[10px] top-[24px]">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mr-8"
          >
            <path
              d="M15 6L9 12L15 18"
              stroke="#232D42"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="sm:hidden">
          <div className="relative w-44 h-[200px] bg-gray-300 mx-auto after:absolute after:bottom-[-96px] after:left-0 after:w-44 after:h-44 after:bg-gray-300 after:rounded-full">
            <div className="absolute bottom-[-86px] left-[9px] w-[158px] border-4 border-gray-200 shadow-md rounded-full mx-auto z-10 overflow-hidden animate-pulse">
              <div className="rounded-full w-[150px] h-[150px] bg-gray-200 mx-auto"></div>
            </div>
          </div>
          <div className="mt-[35%] sm:mt-[18%] md:mt-[15%]">
            <div className="h-[70px] w-[70px] mb-4 rounded-full bg-gray-300 shadow-lg mx-auto animate-pulse"></div>
            <div className="w-52 h-4 bg-gray-300 rounded-full animate-pulse mx-auto mb-2"></div>
            <div className="w-32 h-4 bg-gray-300 rounded-full animate-pulse mx-auto"></div>
          </div>
          <div className="fixed bottom-[70px] p-2 w-full items-center z-50">
            <div className="grid grid-cols-4 gap-3">
              <div className="shadow-md">
                <div className="overflow-hidden">
                  <div className="w-full h-[85px] bg-gray-300 rounded-xl animate-pulse"></div>
                  <div className="p-2">
                    <div className="w-full h-3 bg-gray-300 rounded-full animate-pulse"></div>
                  </div>
                </div>
              </div>
              <div className="shadow-md">
                <div className="overflow-hidden">
                  <div className="w-full h-[85px] bg-gray-300 rounded-xl animate-pulse"></div>
                  <div className="p-2">
                    <div className="w-full h-3 bg-gray-300 rounded-full animate-pulse"></div>
                  </div>
                </div>
              </div>
              <div className="shadow-md">
                <div className="overflow-hidden">
                  <div className="w-full h-[85px] bg-gray-300 rounded-xl animate-pulse"></div>
                  <div className="p-2">
                    <div className="w-full h-3 bg-gray-300 rounded-full animate-pulse"></div>
                  </div>
                </div>
              </div>
              <div className="shadow-md">
                <div className="overflow-hidden">
                  <div className="w-full h-[85px] bg-gray-300 rounded-xl animate-pulse"></div>
                  <div className="p-2">
                    <div className="w-full h-3 bg-gray-300 rounded-full animate-pulse"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden sm:block">
          <div className="flex w-screen">
            <div className="absolute w-[50vw] h-[100vh] bg-gray-300">
              <div className="absolute mt-[12px] ml-[2vw] w-[45vw]">
                <div className="flex items-center content-center">
                  <div className="w-[70px] h-[70px] bg-gray-200 rounded-full animate-pulse"></div>
                  <div className="ml-[20px]">
                    <div className="w-40 h-4 bg-gray-200 rounded-full animate-pulse"></div>
                    <div className="mt-2 w-32 h-3 bg-gray-200 rounded-full animate-pulse"></div>
                  </div>
                </div>
                <div className="absolute top-[calc(50vh-10vw)] left-[13vw] w-[20vw] shadow-md rounded-full z-10 overflow-hidden">
                  <div className="w-full h-[133px] md:h-[166px] lg:h-[204px] bg-gray-200 rounded-full mx-auto animate-pulse"></div>
                </div>
              </div>
            </div>
            <div className="absolute ml-[50vw] w-[50vw] bg-gray-200 text-center h-[100vh] p-12">
              <div className="grid grid-cols-3 gap-3 md:grid-cols-3 md:gap-4">
                <div className="shadow-md rounded-md">
                  <div className="overflow-hidden">
                    <div className="w-full h-[98px] bg-gray-300 rounded-xl animate-pulse"></div>
                  </div>
                  <div className="p-2">
                    <div className="w-full h-4 bg-gray-300 rounded-full animate-pulse"></div>
                  </div>
                </div>
                <div className="shadow-md rounded-md">
                  <div className="overflow-hidden">
                    <div className="w-full h-[98px] bg-gray-300 rounded-xl animate-pulse"></div>
                  </div>
                  <div className="p-2">
                    <div className="w-full h-4 bg-gray-300 rounded-full animate-pulse"></div>
                  </div>
                </div>
                <div className="shadow-md rounded-md">
                  <div className="overflow-hidden">
                    <div className="w-full h-[98px] bg-gray-300 rounded-xl animate-pulse"></div>
                  </div>
                  <div className="p-2">
                    <div className="w-full h-4 bg-gray-300 rounded-full animate-pulse"></div>
                  </div>
                </div>
                <div className="shadow-md rounded-md">
                  <div className="overflow-hidden">
                    <div className="w-full h-[98px] bg-gray-300 rounded-xl animate-pulse"></div>
                  </div>
                  <div className="p-2">
                    <div className="w-full h-4 bg-gray-300 rounded-full animate-pulse"></div>
                  </div>
                </div>
                <div className="shadow-md rounded-md">
                  <div className="overflow-hidden">
                    <div className="w-full h-[98px] bg-gray-300 rounded-xl animate-pulse"></div>
                  </div>
                  <div className="p-2">
                    <div className="w-full h-4 bg-gray-300 rounded-full animate-pulse"></div>
                  </div>
                </div>
                <div className="shadow-md rounded-md">
                  <div className="overflow-hidden">
                    <div className="w-full h-[98px] bg-gray-300 rounded-xl animate-pulse"></div>
                  </div>
                  <div className="p-2">
                    <div className="w-full h-4 bg-gray-300 rounded-full animate-pulse"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Bottom Menu --> */}
        {this.state.flagPotraitPhone && (
          <div className="fixed bottom-0 w-full bg-white rounded-t-2xl py-3 drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)] z-50">
            <div className="grid grid-cols-5">
              <div className="flex justify-center items-center">
                <center>
                  <div className="flex flex-col animate-pulse">
                    <div>
                      <div className="w-6 h-6 bg-gray-300 rounded-lg"></div>
                    </div>
                    <div className="w-12 h-3 mt-1 bg-gray-300 rounded-full"></div>
                  </div>
                </center>
              </div>
              <div className="flex justify-center items-center">
                <center>
                  <div className="flex flex-col animate-pulse">
                    <div>
                      <div className="w-6 h-6 bg-gray-300 rounded-lg"></div>
                    </div>
                    <div className="w-12 h-3 mt-1 bg-gray-300 rounded-full"></div>
                  </div>
                </center>
              </div>
              <div className="flex justify-center items-center animate-pulse">
                <center>
                  <div className="flex flex-col relative">
                    <div className="w-[52px] h-[52px] bg-gray-300 rounded-full drop-shadow-md p-3 items-center absolute top-[-35px] left-[-1px]"></div>
                    <div className="w-12 h-3 mt-[29px] bg-gray-300 rounded-full"></div>
                  </div>
                </center>
              </div>
              <div className="flex justify-center items-center">
                <center>
                  <div className="flex flex-col animate-pulse">
                    <div>
                      <div className="w-6 h-6 bg-gray-300 rounded-lg"></div>
                    </div>
                    <div className="w-12 h-3 mt-1 bg-gray-300 rounded-full"></div>
                  </div>
                </center>
              </div>
              <div className="flex justify-center items-center">
                <center>
                  <div className="flex flex-col animate-pulse">
                    <div>
                      <div className="w-6 h-6 bg-gray-300 rounded-lg"></div>
                    </div>
                    <div className="w-12 h-3 mt-1 bg-gray-300 rounded-full"></div>
                  </div>
                </center>
              </div>
            </div>
          </div>
        )}
        {/* <!-- End Bottom Menu --> */}
        <div
          className={
            this.state.width > "650" &&
            this.state.height < "500" &&
            "fixed bottom-6 right-6 z-50 h-14 w-14 bg-gray-300 animate-pulse rounded-full items-center shadow-lg block"
          }
        ></div>
        <div
          className={
            this.state.width < "500" &&
            this.state.height > "650" &&
            "fixed bottom-6 right-6 z-50 h-14 w-14 bg-gray-300 animate-pulse rounded-full items-center shadow-lg hidden"
          }
        ></div>
      </div>
    );
  }
}

class SkeletonYoutube extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      floatMenu: false,
      width: 0,
      height: 0,
      flagPotraitPhone: false,
    };
  }

  componentDidMount = async () => {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
    // console.log('innerWidth = ' + window.innerWidth)
    // console.log('innerHeight = ' + window.innerHeight)
    if (window.innerWidth < 500 && window.innerHeight > 650) {
      // console.log("disini")
      this.setState({ flagPotraitPhone: true });
    } else if (window.innerWidth > 700 && window.innerHeight > 500) {
      // console.log("disini 1")
      this.setState({ flagPotraitPhone: true });
    } else {
      // console.log("disini 2")
      this.setState({ flagPotraitPhone: false });
    }
    // console.log('innerWidth = ' + this.state.width)
    // console.log('innerHeight = ' + this.state.height)
  };

  render() {
    return (
      <div>
        <nav className="p-4 fixed flex w-full top-0 z-10 bg-orange shadow-md">
          <div className="text-lg text-white font-semibold items-center">
            Video
          </div>
        </nav>
        <div className="pt-[60px] mb-24">
          <div className="mb-2.5 shadow-md">
            <div className="relative w-full h-60 bg-gray-300 animate-pulse"></div>
            <div className="flex min-h-[80px] p-2.5 bg-white items-center">
              <div className="mr-3">
                <div className="w-11 h-11 overflow-hidden rounded-full bg-gray-300 animate-pulse"></div>
              </div>
              <div className="w-11/12 animate-pulse">
                <div className="mb-1 w-56 h-4 bg-gray-300 rounded-full"></div>
                <div className="mt-2 w-32 h-4 bg-gray-300 rounded-full"></div>
              </div>
            </div>
          </div>
          <div className="sm:block md:hidden">
            <div className="px-4">
              <div className="flex mb-2.5 p-2.5 bg-white border rounded-xl shadow-md">
                <div className="w-[90px] h-[90px] mr-3 bg-gray-300 rounded-xl animate-pulse"></div>
                <div className="w-8/12 animate-pulse">
                  <div className="w-52 h-4 mb-2 bg-gray-300 rounded-full"></div>
                  <div className="w-40 h-4 bg-gray-300 rounded-full"></div>
                </div>
              </div>
              <div className="flex mb-2.5 p-2.5 bg-white border rounded-xl shadow-md">
                <div className="w-[90px] h-[90px] mr-3 bg-gray-300 rounded-xl animate-pulse"></div>
                <div className="w-8/12 animate-pulse">
                  <div className="w-52 h-4 mb-2 bg-gray-300 rounded-full"></div>
                  <div className="w-40 h-4 bg-gray-300 rounded-full"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden md:block">
            <div className="mt-4 mb-4 px-4 grid grid-cols-4 gap-4">
              <div className="rounded-xl shadow-md">
                <div className="h-[120px] overflow-hidden animate-pulse">
                  <div className="w-full h-[200px] bg-gray-300 rounded-xl"></div>
                </div>
                <div className="p-2 min-h-[116px]">
                  <div className="w-36 h-4 mb-2 bg-gray-300 rounded-full"></div>
                  <div className="w-24 h-4 bg-gray-300 rounded-full"></div>
                </div>
              </div>
              <div className="rounded-xl shadow-md">
                <div className="h-[120px] overflow-hidden animate-pulse">
                  <div className="w-full h-[200px] bg-gray-300 rounded-xl"></div>
                </div>
                <div className="p-2 min-h-[116px]">
                  <div className="w-36 h-4 mb-2 bg-gray-300 rounded-full"></div>
                  <div className="w-24 h-4 bg-gray-300 rounded-full"></div>
                </div>
              </div>
              <div className="rounded-xl shadow-md">
                <div className="h-[120px] overflow-hidden animate-pulse">
                  <div className="w-full h-[200px] bg-gray-300 rounded-xl"></div>
                </div>
                <div className="p-2 min-h-[116px]">
                  <div className="w-36 h-4 mb-2 bg-gray-300 rounded-full"></div>
                  <div className="w-24 h-4 bg-gray-300 rounded-full"></div>
                </div>
              </div>
              <div className="rounded-xl shadow-md">
                <div className="h-[120px] overflow-hidden animate-pulse">
                  <div className="w-full h-[200px] bg-gray-300 rounded-xl"></div>
                </div>
                <div className="p-2 min-h-[116px]">
                  <div className="w-36 h-4 mb-2 bg-gray-300 rounded-full"></div>
                  <div className="w-24 h-4 bg-gray-300 rounded-full"></div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
    );
  }
}

const SkeletonLoader = () => {
  return (
    <div
      style={{
        flexGrow: "1",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#ecf0f1",
      }}
    >
      <div
        style={{
          width: "100%",
          height: 320,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#ecf0f1",
          position: "sticky",
          top: 0,
          left: 0,
          right: 0,
        }}
      >
        <div className="relative mt-[56px] px-4 py-6 h-80 bg-gray-300 rounded-bl-3xl animate-pulse">
          <div className="absolute w-32 h-32 left-[35%] sm:left-[45%] md:left-[40%] rounded-full bg-gray-300 animate-pulse" />
          <h3 className="text-center mt-40 text-xl font-bold text-slate-200 line-clamp-2">
            <div className="w-3/4 h-6 bg-gray-300 mx-auto animate-pulse" />
          </h3>
          <h4 className="text-sm text-center text-slate-400 line-clamp-1">
            <div className="w-1/2 h-4 bg-gray-300 mx-auto animate-pulse" />
          </h4>
        </div>
      </div>
      <div
        style={{ marginTop: "32px", marginBottom: "10px" }}
        className="z-0 relative sm:mb-0 sm:mt-[280px] md:mb-[0px] bg-white before:absolute before:top-[-25px] before:left-0 before:w-full before:h-[25px] before:bg-white before:rounded-t-2xl after:absolute after:w-20 after:h-1 after:top-[-14px] after:left-[38%] after:sm:left-[44%] after:bg-gray-200 after:rounded-full"
      >
        <div
          style={{
            marginLeft: "10px",
            marginRight: "10px",
            mt: 4,
            mb: 4,
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
          }}
        >
          {Array.from({ length: 5 }).map((_, index) => (
            <div key={index} className="flex flex-col mt-2 space-y-2">
              <div className="w-full h-6 bg-gray-300 animate-pulse" />
              <div className="w-full h-40 bg-gray-300 animate-pulse" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export {
  SkeletonLocation,
  SkeletonHomePage,
  SkeletonCategory,
  SkeletonBerita,
  SkeletonTitikBaca,
  SkeletonBukuTerlaris,
  SkeletonVideo,
  SkeletonBanyakDibaca,
  SkeletonMaps,
  SkeletonLocationSaatIni,
  SkeletonLocationItems,
  SkeletonHalaman,
  SkeletonHalamanBerita,
  SkeletonBeritaKominfo,
  SkeletonDetailBook,
  SkeletonBeritaHomePage,
  SkeletonRadioDetail,
  SkeletonYoutube,
  SkeletonHeadBerita,
  SkeletonLoader,
  SkeletonBanyakDibacaTothem,
};
